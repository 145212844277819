/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
    	  $(".blueRoundLinkI").height($(".blueRoundLinkI").width());
    	  $(".blueRoundLinkI").css("line-height", $(".blueRoundLinkI").width()+"px");
    	  $( window ).resize(function() {
    		  $(".blueRoundLinkI").height($(".blueRoundLinkI").width());
        	  $(".blueRoundLinkI").css("line-height", $(".blueRoundLinkI").width()+"px");
    	  });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'contact': {
      init: function() {
    	  // you want to enable the pointer events only on click;

          $('#map_canvas1').addClass('scrolloff'); // set the pointer events to none on doc ready
          $('#canvas1').on('click', function () {
              $('#map_canvas1').removeClass('scrolloff'); // set the pointer events true on click
          });

          // you want to disable pointer events when the mouse leave the canvas area;

          $("#map_canvas1").mouseleave(function () {
              $('#map_canvas1').addClass('scrolloff'); // set the pointer events to none when mouse leaves the map area
          });
      }
    },
    'contact-us': {
        init: function() {
      	  // you want to enable the pointer events only on click;

            $('#map_canvas1').addClass('scrolloff'); // set the pointer events to none on doc ready
            $('#canvas1').on('click', function () {
                $('#map_canvas1').removeClass('scrolloff'); // set the pointer events true on click
            });

            // you want to disable pointer events when the mouse leave the canvas area;

            $("#map_canvas1").mouseleave(function () {
                $('#map_canvas1').addClass('scrolloff'); // set the pointer events to none when mouse leaves the map area
            });
        }
      },
   // Cours de Francais
   'page_template_objec': {
	   init: function() {
		   jQuery(window).load(function () {
			   var $maxHeight=0;
			   $( ".vCont2" ).each(function() {
				   if($( this ).height() > $maxHeight) {
					   $maxHeight = $( this ).height();
				   }
			   });
			   $maxHeight = $maxHeight + 5;
			   $( ".vCont2" ).each(function() {
					   $( this ).height($maxHeight);
			   });
			   
			   var $maxHeight=0;
			   $( ".vCont" ).each(function() {
				   if($( this ).height() > $maxHeight) {
					   $maxHeight = $( this ).height();
				   }
			   });
			   $maxHeight = $maxHeight + 5;
			   $( ".vCont" ).each(function() {
					   $( this ).height($maxHeight);
			   });
		   });
	   }
   }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
